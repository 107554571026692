import { useEffect, useRef, useState } from 'react'
import './slot-machine.css'
import { requestGetOrderNow, requestGetSlotMachines } from './api-slot-machine'
import { getImageFromServer, runTimeout } from 'utils/funcHelper'
import { BuyButton, ModalProduct, RollButton, Title } from './styled'
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons'
import { formatPrice } from 'utils/ruleForm'
import { ADMIN_ROUTER_PATH } from 'common/config'
import history from 'utils/history'
import { requestAnOrder, sendOrder } from '../api/ApiOrder'
import { useLocation } from 'react-router-dom'
import { message, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import BlueIconComp from 'common/components/Icons/BlueIconComp'
import { LightBulbIcon } from 'common/components/Icons'
import moment from 'moment'

import { useAppSelector } from 'redux/store/store'
import { getUserAnalytic } from 'services/api/CommonApi'

import {
  requestLevelCategory,
  requestLevelDetail,
  requestLevelList,
} from '../../home/Api/LevelApi'

const styleIcon = { fontSize: '32px', color: 'white' }
export interface SlotMachineInterface {
  _id: string
  name: string
  image: string
  price: number
  category: string
  created_at: Date
  updated_at: Date
}
export function SlotMachine({ levelCategory, userInfo }: any) {
  const [data, setData] = useState<any>({})
  const [items, setItems] = useState<SlotMachineInterface[]>([])
  const [order, setOrder] = useState<any>({})
  const [rolledItem, setRolledItem] = useState<SlotMachineInterface | null>(
    null
  )
  const [rolling, setRolling] = useState(false)
  let slotRef: any = useRef(null)
  const { t } = useTranslation()
  const [dataLevel, setDataLevel] = useState<any>({})
  const [analyticDetail, setAnalyticDetail] = useState<any>()

  useEffect(() => {
    ; (async () => {
      const data = await getSlotMachines()
      setItems(data)
    })()
    getLevelList()
      ; (async () => {
        const analyticData = await getUserAnalytic()
        setAnalyticDetail(analyticData.data)
      })()
    if (userInfo?.level)
      getData(userInfo?.level)
  }, [])

  const getData = async (key: any) => {
    try {
      const res = await requestAnOrder(key)
      setData(res.data)
    } catch (error) {
      history.goBack()
      // message.error('Có lỗi xảy ra')
    }
  }

  const getLevelList = async () => {
    try {
      const res = await requestLevelList()

      const ownLevel = userInfo?.level

      let currentLevelData = res.data.find((item: any) => item.key == ownLevel)
      currentLevelData = currentLevelData ? currentLevelData : res.data[0]

      setDataLevel(currentLevelData)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const roll = async () => {
    setRolling(true)

    const selected = await triggerSlotRotation()
    setRolledItem(selected)
    setRolling(false)
  }

  const getSlotMachines = async (): Promise<SlotMachineInterface[]> => {
    try {
      const response = await requestGetSlotMachines()
      return response.data
    } catch (error) {
      return []
    }
  }

  const getProductOrder = async () => {
    try {
      const response = await requestGetOrderNow()
      return response.data
    } catch (error) {
      return null
    }
  }
  const createOrder = async () => {
    const key = userInfo?.level || dataLevel?.key
    try {
      const res = await requestAnOrder(key)
      return res?.data
    } catch (error) {
      throw error
    }
  }
  const purchaseOrder = async () => {
    try {
      const res = await sendOrder(order?._id)
      if (res.data?.status === 'Success') {
        notification.success({
          message: t('order'),
          description: t('send_order_success'),
        })
      }
      window.location.reload();
    } catch (error) {
      throw error
    } finally {
      setRolledItem(null)
    }
  }

  function setTop(top: number) {
    slotRef.current.style.top = `${top}px`
  }
  const triggerRollToItemAtIndex = (index: number) => {
    let options = slotRef.current.children
    let choosenOption = options[index]
    setTop(-choosenOption.offsetTop + 35)
  }

  const triggerSlotRotation = async () => {
    const ms = items.length * 12
    const interval = setInterval(() => {
      // roll to bottom
      triggerRollToItemAtIndex(items.length - 1)
      // roll to top
      setTimeout(() => {
        triggerRollToItemAtIndex(0)
      }, ms)
    }, ms * 1.8)
    let indexSelected = -1
    let product = null
    try {
      let [{ value }]: any = await Promise.allSettled([
        getProductOrder(),
        runTimeout(3500),
      ])
      if (!value) {
        value = await createOrder()
        // history.push({
        //   pathname: ADMIN_ROUTER_PATH.BILL,
        //   state: {
        //     own_level: userInfo?.level,
        //     first_level: dataLevel?.key,
        //   },
        // })
      }
      if (!value) {
        throw new Error('No order created')
      }
      setOrder(value)
      const selected = value.product
      indexSelected = items.findIndex(item => item._id === selected._id)
      product = items[indexSelected] ? items[indexSelected] : value.product
      // if (indexSelected === -1) {
      //   history.push({
      //     pathname: ADMIN_ROUTER_PATH.BILL,
      //     state: {
      //       own_level: userInfo?.level,
      //       first_level: dataLevel?.key,
      //     },
      //   })
      // }
    } catch {
      //
    } finally {
      clearInterval(interval)
      triggerRollToItemAtIndex(indexSelected !== -1 ? indexSelected : 0)
    }
    return product
  }

  const updateAnalyticDetail = async () => {
    try {
      const analyticData = await getUserAnalytic()
      setAnalyticDetail(analyticData.data)
    } catch (error) {
      console.error('Failed to fetch analytic details:', error)
    }
  }

  useEffect(() => {
    if (rolledItem) {
      updateAnalyticDetail()
    }
  }, [rolledItem])

  const renderModal = () => {
    return (
      <ModalProduct
        title={t('order_detail')}
        open={!!rolledItem}
        footer={null}
        onCancel={() => {
          setRolledItem(null)
        }}
      >
        <div className="wrap-product">
          {/* <Title>
            <BlueIconComp icon={<LightBulbIcon style={styleIcon} />} />
            {t('order_unit')}
          </Title> */}
          {/* <div style={{ marginTop: '1rem' }}>
            {t('name_product')}: {rolledItem?.name}
          </div>
          <div>
            {t('time_reciver')}：
            {order?.created_at &&
              moment(new Date()).format('DD/MM/YYYY HH:mm:ss')}
          </div>
          <div>
            {t('code')}：{order?._id}
          </div> */}
          <div className="info">
            {t('account_balance')}：{formatPrice(userInfo?.balance)} Điểm
          </div>
          <div>
            {t('commission')} {analyticDetail?.total_commission_today} Điểm
          </div>
          {/* <b>
            {' '}
            {t('commission_percent')}: {userInfo?.limited_order?.commission ? userInfo?.limited_order?.commission : dataLevel?.commission_percent} %
          </b> */}
          <div className="img-product">
            <img
              src={getImageFromServer(rolledItem?.image as string)}
              alt="product"
              crossOrigin="anonymous"
            />
          </div>
          <div className="price-product">
            {/* <div className="price-block">
              <span> {t('price')}：:</span>
              <div style={{ color: 'var(--orange-1)' }}>
                $ {formatPrice(rolledItem?.price)}
              </div>
            </div>

            <div className="price-block">
              <span> {t('profit')}：:</span>
              <div style={{ color: 'var(--green-1)' }}>
                $ {formatPrice(order?.meta?.commission)}
              </div>
            </div>

            <div className="price-block">
              <span> {t('got_money')}:</span>
              <div style={{ color: 'var(--green-1)' }}>
                ${' '}
                {formatPrice(
                  ((rolledItem?.price as number) *
                    levelCategory.commission_percent) /
                  100
                )}
              </div>
            </div> */}
            <div className="infoText">
              {order?.meta?.order_limit == 1 ? (
                <p className="centered-text">
                  Chúc mừng bạn nhận được đơn hàng may mắn. Số điểm quy đổi là:
                  <span className="highlight"> {userInfo?.limited_order?.price}</span> Điểm
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="info">
              {t('commission_percent')}: {order?.meta?.order_limit == 1 ? userInfo?.limited_order?.commission : analyticDetail?.commission_level} %
            </div>
            <div className="info">
              {t('total_order_today')}: {userInfo?.total_order}/{analyticDetail?.total_order_level_now}
            </div>
          </div>
          <BuyButton
            icon={<ShoppingCartOutlined />}
            onClick={() => {
              purchaseOrder()
              // history.push({
              //   pathname: ADMIN_ROUTER_PATH.BILL,
              //   state: {
              //     own_level: userInfo?.level,
              //     first_level: dataLevel?.key,
              //   },
              // })
            }}
          >
            {t('update')}
          </BuyButton>
        </div>
      </ModalProduct >
    )
  }

  return (
    <div className="slot-machine-container">
      <CaretLeftOutlined className="arrow-icon arrow-icon-left" />
      <CaretRightOutlined className="arrow-icon arrow-icon-right" />
      <div className="slot">
        <section className="slot-machine-list">
          <div className="container" ref={slotRef}>
            {items.map((item, idx) => (
              <div key={idx} className="slot-item">
                <img
                  src={getImageFromServer(item.image)}
                  alt="product"
                  crossOrigin="anonymous"
                />
                <img
                  src={getImageFromServer(item.image)}
                  alt="product"
                  crossOrigin="anonymous"
                />
                <img
                  src={getImageFromServer(item.image)}
                  alt="product"
                  crossOrigin="anonymous"
                />
              </div>
            ))}
          </div>
        </section>
      </div>
      <RollButton onClick={!rolling && roll} disabled={rolling}>
        {rolling ? t('order_start') : t('get_order')}
      </RollButton>
      {renderModal()}
    </div>
  )
}
