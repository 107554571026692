export const API_STATUS = {
  RE_LOGIN: 401,
  NOT_FOUND: 404,
}
export const CONFIG_TYPE = {
  ATTENDANCE: 1,
  ORDER_PROMOTION: 2,
  DAILY_TURN: 3,
  REFERRAL_APP: 4,
  REFERRAL_CODE: 5,
  REFERRAL_MEMBER: 6,
}

export const REQUEST_STATUS = {
  PENDING: 0,
  SUCCESS: 1,
  REFUSE: 2,
}
export const GENDER = {
  MALE: 0,
  FEMALE: 1,
}
export const REG_PHONE = /(84|0[3|5|7|8|9])+([0-9]{8})\b/

export const IS_ACTIVE = {
  ACTIVE: 1,
  INACTIVE: 0,
}

export const TRANSACTION_STATUS = {
  PENDING: 'Pending',
  SUCCESS: 'Success',
  REJECT: 'Reject',
}

export const TRANSACTION_TYPE = {
  TOPUP: 'TopUp',
  CASH_OUT: 'CashOut',
  PURCHASE_LEVEL: 'PurchaseLevel',
}

export const UserBankNames = {
  MartimeBank: 'MARITIME BANK',
  AgriBank: 'AGRIBANK',
  VietinBank: 'VIETINBANK',
  BacABank: 'BAC A BANK',
  BaoVietBank: 'BAO VIET BANK',
  BidvBank: 'BIDV BANK',
  GPBank: 'GP BANK',
  HDBank: 'HD BANK',
  MBBank: 'MBBANK',
  AChauBank: 'NGAN HANG A CHAU',
  TMCPDongABank: 'Ngân hàng TMCP Đông Á',
  TMCPVietABank: 'Ngân hàng TMCP Việt Á',
  LDVietNgaBank: 'NH LD VIET NGA',
  MTVCIMBBank: 'NH MTV CIMB',
  TMCPQuocDanBank: 'NH TMCP QUOC DAN',
  OceanBank: 'OCEANBANK',
  PGBBank: 'PGBANK',
  PhuongDongBank: 'PHUONGDONG BANK',
  SacomBank: 'SACOMBANK',
  SCBBank: 'SCB BANK',
  SeaBank: 'SEABANK',
  SHBBank: 'SHB BANK',
  ShinHanBank: 'SHINHAN BANK VN',
  TechcomBank: 'TECHCOMBANK',
  TienPhongBank: 'TIENPHONG BANK',
  UnitedOverseasBank: 'UNITED OVERSEAS BANK',
  VIBBank: 'VIB BANK',
  VIDPublicBank: 'VIDPublic Bank',
  VietBank: 'VIETBANK',
  VietcomBank: 'VIETCOMBANK',
  VPBank: 'VPBANK',
  LienVietPostBank: 'LienVietPostBank',
  EximBank: 'EXIMBANK',
  CitiBank: 'Citi Bank',
  BanVietBank: 'Ban Viet Bank',
  NCBBank: 'NCB Bank',
  SaigonBank: 'SAIGON Bank',
  HSBCBank: 'HSBC Bank',
  OCBCBank: 'OCBC Bank',

}

export enum IsBlockCreateOrder {
  Blocked = 0,
  NonBlocked,
}

// export const CHAT_LINK = 'https://direct.lc.chat/14744685/'
export const CHAT_LINK = 'https://secure.livechatinc.com/licence/18641703/v2/open_chat.cgi'
