import { Button, Modal } from 'antd'
import styled from 'styled-components'
export const RollButton: any = styled(Button)`
  width: 100%;
  background-color: #1890ff;
  border-color: #1890ff;
  margin-top: 1rem;
  font-weight: 600;
  color: white;
`

export const ModalProduct = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .wrap-product {
    padding: 1rem;
    box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
    background-color: var(--background-color-1);
  }
  .name-product {
    font-size: 18px;
    color: var(--primary-title);
    font-weight: 600;
  }
  .img-product {
    margin-top: 1rem;
  }
  .price-product {
    margin-top: 1rem;
    font-weight: 600;
    .price-block {
      display: flex;
      span {
        margin-right: 8px;
      }
    }
  }
  img {
    object-fit: cover;
    width: 60%;
    align-items: center;
    display: block; /* Chuyển thành phần img thành khối để margin hoạt động */
    margin: 0 auto; /* Căn giữa theo chiều ngang */
  }

  .infoText {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .centered-text {
    font-size: 18px; /* Adjust the font size as needed */
    color: green;
  }

  .highlight {
    color: red;
  }
  .content {
    color: var(--orange-1);
  }
`
export const BuyButton = styled(Button)`
  width: 100%;
  background-color: var(--second-color);
  border-color: var(--second-color);
  margin-top: 1rem;
  font-weight: 600;
  color: white;
  :hover {
    color: var(--primary-color);
    background-color: var(--second-color);
    border-color: var(--second-color);
  }
`
export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
`
